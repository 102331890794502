import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react';
import { cn } from '~/utils/misc';

const dotVariants = cva('inline-block leading-none rounded-full', {
    variants: {
      varient: {
        default: 'bg-slate-500',
        loading: 'bg-green-600',
        unloading: 'bg-orange-500',
        interim: 'bg-primary',
        secondary: 'bg-secondary',
      },
      text: {
        nill: 'w-3 h-3',
        present: 'w-4 h-4 text-center text-white text-[10px]',
      },
      showAs: {
        indicator: 'absolute -right-1 -top-1 h-2 w-2 border border-white',
      },
      size: {
        sm: 'w-2 h-2',
        md: 'w-3 h-3',
        lg: 'w-4 h-4',
      },
    },
    defaultVariants: {
      varient: 'default',
      text: 'nill',
    },
  })
export interface DotProps
  extends React.HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof dotVariants> {}

const Dot = React.forwardRef<HTMLSpanElement, DotProps>(
  ({ className, varient, text, size, showAs, ...props }, ref) => {
    return (
      <span
        className={cn(dotVariants({ className, varient, text, size, showAs }))}
        ref={ref}
        {...props}
      />
    );
  },
);

// Set the display name for the component
Dot.displayName = 'Dot';

export default Dot;
